
import { defineComponent, ref, onMounted } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { config } from "@/core/helpers/config";
import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import variables from "@/router/api";
import ReceiptsTable from "@/components/reusable/ReceiptsTable.vue";
import InvoicesTable from "@/components/reusable/InvoicesTable.vue";
import PolicySummary from "@/components/reusable/policy-information/CooperativeUmbrellaPolicySummary.vue";

export default defineComponent({
  name: "client-house-insurance-policy-information",
  components: {
    ReceiptsTable,
    InvoicesTable,
    PolicySummary
  },
  data() {
    return {
      policySummary: {},
      buildings: [],
      receipts: [],
      invoices: [],
      policies: [],
      publicId: this.$route.params.publicId,
      route: ""
    };
  },
  setup() {
    const tabIndex = ref(0);

    onMounted(() => {
      // set the tab from previous
      tabIndex.value = parseInt(localStorage.getItem("builderTab") || "0");
      setCurrentPageBreadcrumbs("Building", [
        "Policy",
        "Policy Information",
        "House Insurance"
      ]);
    });

    /**
     * Set active tab when the tab get clicked
     * @param event
     */
    const setActiveTab = (event) => {
      tabIndex.value = parseInt(event.target.getAttribute("data-tab-index"));
      // keep active tab
      localStorage.setItem("builderTab", tabIndex.value.toString());
    };

    return {
      tabIndex,
      config,
      setActiveTab
    };
  },
  created() {
    // Get policy information
    this.policyInformation();

    //Set page title
    document.title =
      "Cooperative Admin: Members Building House Insurance Policies | " +
      process.env.VUE_APP_TITLE;
  },
  methods: {
    policyInformation() {
      // Fetch the members policies
      if (JwtService.getToken()) {
        ApiService.setHeader();
        ApiService.get(
          `${variables.COOPERATIVE_UMBRELLA_POLICY_ROUTE}/${this.publicId}`
        )
          .then(({ data }) => {
            // Assign response to array variable
            this.policies = data.data.policies;
            this.policySummary = data.data.policySummary;
            this.receipts = data.data.receipts;
            this.invoices = data.data.invoices;
          })
          .catch(function (error) {
            variables.toastAlert(error.response.data.error, "error");
          });
      }
    },
    formatDate(dateString) {
      return variables.formatDate(dateString);
    },
    addCommaToNumberString(amount) {
      return variables.addCommaToNumberString(amount);
    }
  }
});
